<template>
  <div>
    <df-modal
      persistent
      :action-name="modalActionName"
      :loading="loading"
      :title="modalTitle"
      @action-click="register"
      @close="closeAddUserModal"
    >
      <template #card-content>
        <v-form ref="form" class="df-flex-xxl df-flex-col add-user-modal">
          <div class="df-flex-l">
            <div class="df-col-6">
              <df-input
                v-model="name"
                :placeholder="$t('Users.modal.name_placeholder')"
                :label="$t('Users.modal.name')"
                :rules="[required]"
              />
            </div>
            <div class="df-col-6">
              <df-input
                v-model="email"
                :placeholder="$t('Users.modal.email_placeholder')"
                :label="$t('Users.email')"
                :rules="[required, validEmail]"
              />
            </div>
          </div>
          <df-select
            v-model="profile"
            :placeholder="$t('Users.modal.profile_placeholder')"
            :items="translatedProfiles"
            :label="$t('Users.modal.profile')"
            :rules="[required]"
          />
          <df-multiple-select
            v-model="selectedFarms"
            clearable
            :placeholder="$t('Users.modal.farms_placeholder')"
            :items="formattedFarms"
            :label="$t('Users.modal.farms')"
          />
        </v-form>
      </template>
    </df-modal>
    <exceeded-users-modal
      v-if="isExceededUsersModalOpened"
      @close="closeExceededUsersModal"
    />
  </div>
</template>

<script>
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import DfMultipleSelect from '@/lib/components/Select/DfMultipleSelect.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
const ExceededUsersModal = () =>
  import('@/modules/users/components/modal/ExceededUsersModal.vue')
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import { required, validEmail } from '@/utils/formValidations'

export default {
  name: 'AddUserModal',

  components: {
    DfInput,
    DfModal,
    DfMultipleSelect,
    DfSelect,
    ExceededUsersModal,
  },

  props: {
    user: {
      default: () => ({}),
      type: Object,
    },
  },

  data() {
    return {
      email: '',
      isExceededUsersModalOpened: false,
      loading: false,
      name: '',
      profile: {},
      required,
      selectedFarms: [],
      validEmail,
    }
  },

  mounted() {
    if (this.isEditing) {
      this.email = this.user.email
      this.name = this.user.name
      this.profile = this.translatedProfiles.find(
        (profile) => profile.value == this.user.profile_id
      )
      this.selectedFarms = this.farms.reduce((acc, farm) => {
        if (this.user.farms.includes(farm.id)) {
          acc.push({
            name: farm.name,
            value: String(farm.id),
          })
        }

        return acc
      }, [])
    }
  },

  inject: {
    getProfiles: {
      from: 'getProfiles',
    },
  },

  computed: {
    ...mapGetters('farms', ['farms']),
    formattedFarms() {
      return this.farms.map((farm) => {
        return {
          name: farm.name,
          value: String(farm.id),
        }
      })
    },
    isEditing() {
      return this.user.id
    },
    modalActionName() {
      return this.isEditing
        ? this.$t('Users.modal.edit')
        : this.$t('Users.modal.add')
    },
    modalTitle() {
      return this.isEditing
        ? this.$t('Users.modal.edit_user')
        : this.$t('Users.modal.register_user')
    },
    translatedProfiles() {
      return this.getProfiles().map((profile) => {
        return {
          name:
            this.$t('Users.profiles.' + profile.uid) +
            ' - ' +
            this.$t('Users.profiles.description.' + profile?.uid),
          value: String(profile.id),
        }
      })
    },
  },

  methods: {
    ...mapActions('user', ['createUser', 'updateUser']),
    async addUser() {
      const params = {
        active: true,
        email: this.email,
        farms: this.selectedFarms.map((farm) => Number(farm.value)),
        name: this.name,
        profile_id: Number(this.profile.value),
      }
      this.loading = true
      try {
        await this.createUser(params)
        logEvent(events.usersModule.create)
      } catch (error) {
        if (error.response?.data?.key === 'exception.users.exceeded_limit') {
          this.isExceededUsersModalOpened = true
        }
        throw new Error(error)
      } finally {
        this.loading = false
      }
    },
    closeAddUserModal() {
      this.$emit('close')
    },
    closeExceededUsersModal() {
      this.isExceededUsersModalOpened = false
      this.$emit('close')
    },
    async editUser() {
      const profileId = Number(this.profile.value)
      const params = {
        active: true,
        email: this.email,
        farms: this.selectedFarms.map((farm) => Number(farm.value)),
        id: this.user.id,
        name: this.name,
        profile_id: profileId,
        profileUid: this.getProfiles().find(
          (profile) => profile.id == profileId
        ),
      }
      this.loading = true
      try {
        await this.updateUser(params)
        logEvent(events.usersModule.update)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async register() {
      if (!this.$refs.form.validate()) return

      if (this.isEditing) {
        await this.editUser()
      } else {
        try {
          await this.addUser()
        } catch (error) {
          return
        }
      }

      this.$root.$emit(
        'notify',
        'success',
        this.$t('Users.modal.register_success')
      )
      this.closeAddUserModal()
    },
  },
}
</script>
