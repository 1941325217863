<template>
  <v-dialog
    class="dialog-height"
    scrollable
    :persistent="isPersistent"
    :value="true"
    :width="width"
    @click-outside="closeNotPersistentModal"
  >
    <v-card class="df-modal">
      <v-card-title
        class="df-flex-m justify-space-between modal__title-wrapper"
      >
        <h1 class="modal__title">
          {{ title }}
        </h1>
        <button v-if="!disableClose" @click="closeModal">
          <font-awesome-icon
            icon="times"
            size="sm"
            :color="colorIconDisabled"
          />
        </button>
      </v-card-title>
      <v-card-text
        class="modal__content"
        :class="withoutPadding ? 'pa-0' : ''"
        :style="scrollable ? {} : { overflowY: 'hidden' }"
      >
        <slot name="card-content"></slot>
      </v-card-text>
      <slot name="outside-content"></slot>
      <v-card-actions
        v-if="actionName"
        data-id="actions"
        class="df-flex-l justify-end"
      >
        <df-button v-if="!disableClose" variant="secondary" @click="closeModal">
          {{ $t('Modal.standard.cancel') }}
        </df-button>
        <df-button
          data-id="action-button"
          class="ml-0"
          :disabled="disableAction"
          :loading="loading"
          @click="action"
        >
          {{ actionName }}
        </df-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'DfModal',

  components: {
    DfButton,
  },

  props: {
    actionName: {
      default: '',
      type: String,
    },
    disableAction: {
      default: false,
      type: Boolean,
    },
    disableClose: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    scrollable: {
      default: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    width: {
      default: '640px',
      type: String,
    },
    withoutPadding: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      colorIconDisabled: colors.colorIconDisabled,
    }
  },

  computed: {
    isPersistent() {
      return this.persistent || this.disableClose
    },
  },

  methods: {
    action() {
      this.$emit('action-click')
    },
    closeModal() {
      this.$emit('close')
    },
    closeNotPersistentModal() {
      if (!this.persistent) this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-height {
  max-height: 75%;
}
.df-modal {
  .modal__title-wrapper {
    flex-wrap: nowrap;

    .modal__title {
      word-break: break-word;
    }
  }
}
.df-modal .modal__content.v-card__text {
  padding: $spacing-xxl $spacing-xl;

  @include d(m) {
    padding: $spacing-l $spacing-m;
  }
}
::v-deep .v-dialog {
  box-shadow: none;
  border-radius: $border-radius-m;
}
::v-deep .v-card {
  .v-card__title {
    border-bottom: 1px solid $color-border;
    padding: $spacing-xl;

    h1 {
      @include heading-small;
      color: $color-text-secondary;
    }
  }
  .v-card__actions {
    border-top: 1px solid $color-border;
    padding: $spacing-l $spacing-xl;
  }
}
</style>
