<template>
  <df-select-dropdown
    :items="items"
    :search-bar="searchBar"
    :search-placeholder="searchPlaceholder"
    :value="value"
    @click="selectItem"
  >
    <template #default="slotProps">
      <div class="df-flex-sm df-flex-col df-select">
        <h3 v-if="label">{{ label }}<span v-if="hasRequiredRule">*</span></h3>
        <div v-on="slotProps.on">
          <v-text-field
            data-id="select-input"
            :value="componentText"
            dense
            flat
            hide-spin-buttons
            outlined
            readonly
            solo
            :background-color="backgroundColor"
            :disabled="disabled"
            :loading="loading"
            :placeholder="placeholder"
            :rules="rules"
          >
            <template #append>
              <font-awesome-icon class="icon" icon="chevron-down" />
            </template>
          </v-text-field>
        </div>
      </div>
    </template>
  </df-select-dropdown>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DfSelectDropdown from '@/lib/components/Dropdown/DfSelectDropdown.vue'

export default {
  name: 'DfSelect',

  components: {
    DfSelectDropdown,
  },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return typeof item.name === 'string' && typeof item.value === 'string'
        })
      },
    },
    label: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    searchBar: {
      default: false,
      type: Boolean,
    },
    searchPlaceholder: {
      default: '',
      type: String,
    },
    value: {
      required: true,
      type: [Array, Object],
    },
  },

  data() {
    return {
      componentText: '',
      hasRequiredRule: false,
    }
  },

  created() {
    this.rules.some((rule) => {
      if (rule.name === 'required') this.hasRequiredRule = true
    })
  },

  watch: {
    value: {
      handler(newValue) {
        this.componentText = newValue.name
      },
    },
  },

  computed: {
    backgroundColor() {
      return this.disabled
        ? colors.colorBackgroundDisabled
        : colors.colorBackgroundLight
    },
  },

  methods: {
    selectItem(value) {
      this.$emit('input', value)
      this.$emit('click', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.df-select {
  h3 {
    @include label-medium;
    color: $color-text-secondary;

    span {
      color: $color-text-danger;
    }
  }
  .icon {
    color: $color-icon-neutral;
  }
}
// placeholder
::v-deep .v-text-field__slot input::placeholder {
  @include paragraph-medium;
  color: $color-text-neutral;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 0px !important;
}
::v-deep .v-input__slot {
  padding: $spacing-m $spacing-l !important;
  margin-bottom: 0px !important;
  cursor: pointer !important;
}
::v-deep .v-input--is-disabled .v-input__slot {
  cursor: default !important;
}
::v-deep .v-input--is-focused fieldset {
  border-color: $color-border-primary !important;
}
// typed text
::v-deep .v-text-field__slot input {
  @include paragraph-medium;
  color: $color-text-secondary;
  padding: 0px;
  cursor: pointer !important;
}
::v-deep .v-input--is-disabled input {
  cursor: default !important;
}
// error messages
::v-deep .v-text-field__details {
  position: absolute;
  top: 46px;
  padding: 0px !important;

  .v-messages__message {
    @include paragraph-x-small;
    color: $color-text-danger;
  }
}
</style>
